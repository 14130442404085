import React from 'react';

export default ({ size = "1.25em", className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    fill="currentColor"
    className={className}
    {...rest}
  >
    <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
  </svg>
)
