import React from 'react';

export default ({ size = "1.25em", className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    fill="currentColor"
    className={className}
    {...rest}
  >
    <path d="M0 10h24v4h-24z" />
  </svg>
)
