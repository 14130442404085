import { graphql, navigate } from "gatsby"
import React from "react"
import {
  Alert,
  Button,
  ButtonGroup,
  Carousel,
  CarouselItem,
  Input,
  InputGroup,
  InputGroupAddon,
  Tooltip,
} from "reactstrap"

import OrderWrapper from "../components/orderWrapper"
import IconHelp from "../icons/help"
import IconMinus from "../icons/minus"
import IconPlus from "../icons/plus"

export default class Order extends React.Component {
  state = {
    priceSummary: {
      difficulty: 1,
      difficultyName: null,
      numberOfPages: 7,
      priceBeforeModified: 0,
      priceAfterModified: 0,
      percentualPriceModifier: 100,
      realizationDuration: 0,
    },
    ...this.props.location.state,
    step: 3,
  }

  handleChangeDifficulty(difficulty) {
    const summary = this.calculateSummary({ difficulty: difficulty })
    this.setState({
      priceSummary: {
        ...this.state.priceSummary,
        difficulty,
        difficultyName: this.getCurrentService().node.acf.projekt_balicky[
          difficulty
        ].nazev,
        ...summary,
      },
    })
  }

  handleChangeNumberOfPages(newValue) {
    const numberOfPages = newValue <= 1 ? 1 : newValue
    const summary = this.calculateSummary({ numberOfPages: numberOfPages })
    this.setState({
      priceSummary: {
        ...this.state.priceSummary,
        numberOfPages,
        ...summary,
      },
    })
  }

  handleInputChange(event) {
    const numberOfPages =
      event.target.value && event.target.value > 0
        ? parseInt(event.target.value.replace(/\D/g, ""))
        : this.state.priceSummary.numberOfPages
    const summary = this.calculateSummary({ numberOfPages: numberOfPages })
    this.setState({
      priceSummary: {
        ...this.state.priceSummary,
        numberOfPages,
        ...summary,
      },
    })
  }

  calculateSummary({
    difficulty = this.state.priceSummary.difficulty,
    numberOfPages = this.state.priceSummary.numberOfPages,
  }) {
    const currentDifficultyData = this.getCurrentService().node.acf
      .projekt_balicky[difficulty]

    const timeToRealizeProject =
      parseInt(currentDifficultyData.casova_narocnost_hlavni_strany) +
      parseInt(currentDifficultyData.casova_narocnost_podstrany) *
        (numberOfPages - 1)

    const hourRate = parseInt(
      this.props.data.allWordpressAcfOptions.edges[0].node.options
        .obecna_hodinova_sazba
    )

    const calculatePrice = () => {
      let percentualPriceModifier = 100;

      if(numberOfPages < 2) percentualPriceModifier += 50;
      else if(numberOfPages < 3) percentualPriceModifier += 40;
      else if(numberOfPages < 4) percentualPriceModifier += 30;
      else if(numberOfPages < 7) percentualPriceModifier += 20;
      else if(numberOfPages < 10) percentualPriceModifier += 10;
      else if(numberOfPages >= 20) percentualPriceModifier -= 5;

      //console.log(percentualPriceModifier);

      const price = timeToRealizeProject * hourRate

      const priceBeforeModified = price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")

      const priceAfterModified =
        percentualPriceModifier !== 100
          ? ((price * percentualPriceModifier) / 100)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : priceBeforeModified

      return {
        percentualPriceModifier,
        priceBeforeModified,
        priceAfterModified,
      }
    }

    const realizationDuration = () => {
      const timeToRealizeProjectWithPercentageModifier = timeToRealizeProject * calculatePrice().percentualPriceModifier / 100
      const dayCount = Math.ceil(((timeToRealizeProjectWithPercentageModifier / 6) * 3) / 2)

      if (dayCount / 365 > 1) {
        const yearCount = dayCount / (365 - 70)
        const text = yearCount >= 2 ? "let" : "roku"
        return yearCount + " " + text
      } else if (dayCount / 60 > 1) {
        const monthCount = Math.ceil(dayCount / (30 - 4))
        const text = monthCount > 1 ? "měsíců" : "měsíce"
        return monthCount + " " + text
      } else if (dayCount / 10 > 1) {
        const monthCount = Math.ceil(dayCount / (7 - 1.5))
        const text = monthCount > 1 ? "týdnů" : "týdne"
        return monthCount + " " + text
      } else {
        const text = dayCount > 1 ? "dní" : "dne"
        return dayCount + " " + text
      }
    }

    const difficultyName = this.getCurrentService().node.acf.projekt_balicky[
      difficulty
    ].nazev

    return {
      realizationDuration: realizationDuration(),
      timeToRealizeProject,
      difficultyName,
      ...calculatePrice(),
    }
  }

  componentDidMount() {
    if (!this.state.service || !this.state.subService)
      return navigate("objednavka/")

    //recalculate price
    if (this.state.service && this.state.subService) {
      const summary = this.calculateSummary({
        difficulty: this.state.priceSummary.difficulty,
        numberOfPages: this.state.priceSummary.numberOfPages,
      })
      this.setState(prevState => ({
        ...prevState,
        priceSummary: {
          ...prevState.priceSummary,
          ...summary,
        },
      }))
    }
  }

  toggleTooltip(id) {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen ? id : false,
    })
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.priceSummary.difficulty ===
      this.getCurrentService().node.acf.projekt_balicky.length - 1
        ? 0
        : this.state.priceSummary.difficulty + 1
    this.setState({
      priceSummary: {
        ...this.state.priceSummary,
        difficulty: nextIndex,
      },
    })
  }

  getCurrentService() {
    return this.props.data.allWordpressWpNoveSluzby.edges.filter(
      ({ node }) => node.wordpress_id === this.state.subService.id
    )[0]
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.priceSummary.difficulty === 0
        ? this.getCurrentService().node.acf.projekt_balicky.length - 1
        : this.state.priceSummary.difficulty - 1
    this.setState({
      priceSummary: {
        ...this.state.priceSummary,
        difficulty: nextIndex,
      },
    })
  }

  render() {
    if (!this.state.service || !this.state.subService)
      return <OrderWrapper state={this.state} isNextStepDisabled={true} />

    //console.log(this.state)

    const service = this.getCurrentService()

    const slides =
      service &&
      service.node.acf.projekt_balicky.map((item, key) => (
        <CarouselItem
          onExiting={() => this.onExiting()}
          onExited={() => this.onExited()}
          key={key}
        >
          <div dangerouslySetInnerHTML={{ __html: item.popis }} />
        </CarouselItem>
      ))

    return (
      <OrderWrapper state={this.state} isNextStepDisabled={false}>
        <div className="text-center mb-4">
          <h2 className="h1">Spočítejte cenu projektu</h2>
        </div>

        <div className="row justify-content-center mt-n4">
          <div className="col-lg-8 pt-4 d-flex">
            <article className="py-4 px-3 p-sm-4 flex-grow w-100 bg-white rounded">
              <header className="d-flex justify-content-center align-items-baseline mb-3 mb-lg-4">
                <h3 className="text-center mb-0 h2">Náročnost projektu</h3>
                <IconHelp
                  className="position-relative d-inline-block ml-2"
                  style={{ zIndex: 2, cursor: "help" }}
                  id="tooltip-type"
                />
                <Tooltip
                  placement="top"
                  isOpen={this.state.tooltipOpen === `tooltip-type`}
                  target={`tooltip-type`}
                  toggle={() => this.toggleTooltip(`tooltip-type`)}
                >
                  <div className="p-2 text-left">
                    Projekty rozdělujeme do více cenových kategorii. Každá
                    kategorie má svou charakteristiku popsanou níže.
                  </div>
                </Tooltip>
              </header>

              {service && (
                <ButtonGroup className="d-flex">
                  {service.node.acf.projekt_balicky.map((item, key) => (
                    <Button
                      color="secondary"
                      key={key}
                      className="px-2 px-sm-3"
                      active={this.state.priceSummary.difficulty === key}
                      onClick={() => this.handleChangeDifficulty(key)}
                    >
                      {item.nazev}
                    </Button>
                  ))}
                </ButtonGroup>
              )}

              {slides && (
                <Carousel
                  activeIndex={this.state.priceSummary.difficulty}
                  next={() => this.next()}
                  previous={() => this.previous()}
                  interval={false}
                  className="mt-3 mt-lg-4 mb-n3"
                >
                  {slides}
                </Carousel>
              )}
            </article>
          </div>
          <div className="col-lg-8 pt-4 d-flex">
            <article className="py-4 px-3 p-sm-4 flex-grow w-100 bg-white rounded">
              <header className="d-flex justify-content-center align-items-baseline mb-3 mb-lg-4">
                <h3 className="text-center mb-0 h2" id="pages-title">
                  Počet typových stránek
                </h3>
                <IconHelp
                  className="position-relative d-inline-block ml-2"
                  style={{ zIndex: 2, cursor: "help" }}
                  id="tooltip-pages"
                />
                <Tooltip
                  placement="top"
                  isOpen={this.state.tooltipOpen === `tooltip-pages`}
                  target={`tooltip-pages`}
                  toggle={() => this.toggleTooltip(`tooltip-pages`)}
                >
                  <div className="p-2 text-left mb-n3">
                    <p>
                      <strong>Typové stránky jsou</strong> univerzální stránky,
                      pomoci kterých se skladají{" "}
                      <strong>individuální nebo dynamické stránky</strong>.
                    </p>
                    <p>
                      Jsou to například: úvodní stránka, stránka kontakt výpis
                      aktualit a detail aktuality. Zatímco detail aktuality je
                      univerzální typová stránka, samotných aktualit můžete mít
                      neomezené množství, protože všechny využijí typovou
                      stránku detailu.
                    </p>
                    <p>
                      Každý skrytý obsah, který se zobrazuje po uživatelské akci
                      (hover, klik) se počítá jako typová stránka. Příklad
                      skrytého obsahu aplikace: modální okno, menu druhé úrovně,
                      záložky a jeho obsah.
                    </p>
                  </div>
                </Tooltip>
              </header>
              <InputGroup>
                <InputGroupAddon addonType="prepend" className="w-25">
                  <Button
                    color="secondary"
                    onClick={() =>
                      this.handleChangeNumberOfPages(
                        this.state.priceSummary.numberOfPages - 1
                      )
                    }
                    className="w-100 align-self-center"
                  >
                    <IconMinus />
                  </Button>
                </InputGroupAddon>
                <Input
                  type="number"
                  aria-labelledby="pages-title"
                  value={this.state.priceSummary.numberOfPages}
                  onChange={event => this.handleInputChange(event)}
                  className="text-center font-weight-bold h-auto py-0 align-self-stretch"
                  bsSize="lg"
                />
                <InputGroupAddon addonType="append" className="w-25">
                  <Button
                    color="secondary"
                    onClick={() =>
                      this.handleChangeNumberOfPages(
                        this.state.priceSummary.numberOfPages + 1
                      )
                    }
                    className="w-100 align-self-center"
                  >
                    <IconPlus />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </article>
          </div>
          <div className="col-lg-8 pt-4">
            <article className="py-4 px-3 p-sm-4 flex-grow w-100 bg-white rounded">
              <h3 className="d-flex justify-content-between align-items-end mb-0">
                <span className="font-weight-normal">Cena celkem:</span>
                <span className="d-flex flex-column text-right">
                  <strong>
                    {this.state.priceSummary.priceAfterModified} Kč
                  </strong>
                </span>
              </h3>
              <p className="small text-right mb-2 text-muted">
                Uvedená cena je bez DPH. Nejsme plátci DPH.
              </p>
              <h3 className="d-flex justify-content-between mb-0">
                <span className="font-weight-normal">Doba realizace:</span>
                <strong>
                  do {this.state.priceSummary.realizationDuration}
                </strong>
              </h3>
            </article>
          </div>
          {service && service.node.acf.cena_zahrnuje_nebo_ne && (
            <div className="col-lg-6 pt-4 d-flex">
              <Alert color="info" className="py-4 px-3 p-sm-4 mb-0 flex-grow-1">
                <div className="mb-n3 small font-weight-light">
                  {service.node.acf.cena_zahrnuje_nebo_ne.cena_zahrnuje && (
                    <>
                      <h3 className="h5">Cena zahrnuje:</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            service.node.acf.cena_zahrnuje_nebo_ne
                              .cena_zahrnuje,
                        }}
                      />
                    </>
                  )}

                  {service.node.acf.cena_zahrnuje_nebo_ne.cena_nezahrnuje && (
                    <>
                      <h3 className="h5">Cena nezahrnuje:</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            service.node.acf.cena_zahrnuje_nebo_ne
                              .cena_nezahrnuje,
                        }}
                      />
                      <p>Tyto i jakékoliv další úpravy lze řešit vícepráci.</p>
                    </>
                  )}
                </div>
              </Alert>
            </div>
          )}
          <div className="col-lg-6 pt-4 d-flex">
            <Alert
              color="warning"
              className="py-4 px-3 p-sm-4 mb-0 flex-grow-1"
            >
              <div className="mb-n3 small font-weight-light">
                <h3 className="h5">Důležitá poznámka k ceně:</h3>
                <p>
                  Berte prosím výslednou částku orientačně. Ačkoliv je výpočet
                  ceny velmi přesný a ve většině případů odpovídající, objevují
                  se i vyjímky. Může se stát, že váš projekt bude zařazen do
                  jiné náročnosti nebo jeho cena bude posouzena individuálně.
                </p>
                <p>
                  Konečnou částku se dozvíte ihned po kontrole objednávky.
                  Prosíme vás o co nejpodrobnější popis projektu a jeho
                  funkčnosti v následujícím kroku. Urychlíte tak celý proces
                  objednávky.
                </p>
                <p>
                  Jakékoliv vícepráce se budou řešit na bázi odpracovaných hodin
                  dle naši hodinové sazby. Naše hodinová sazba je{" "}
                  {parseInt(
                    this.props.data.allWordpressAcfOptions.edges[0].node.options
                      .obecna_hodinova_sazba
                  )}{" "}
                  Kč bez DPH. Nejsme plátci DPH.
                </p>
              </div>
            </Alert>
          </div>
        </div>
      </OrderWrapper>
    )
  }
}

export const query = graphql`
  query {
    allWordpressWpNoveSluzby(filter: { wordpress_parent: { ne: 0 } }) {
      edges {
        node {
          wordpress_id
          wordpress_parent
          acf {
            s_o_tucny_nadpis
            s_o_normalni_nadpis
            s_o_popis
            s_o_napoveda
            s_o_tag
            projekt_balicky {
              nazev
              casova_narocnost_hlavni_strany
              casova_narocnost_podstrany
              popis
            }
            cena_zahrnuje_nebo_ne {
              cena_zahrnuje
              cena_nezahrnuje
            }
          }
        }
      }
    }

    allWordpressAcfOptions {
      edges {
        node {
          options {
            obecna_hodinova_sazba
          }
        }
      }
    }
  }
`
